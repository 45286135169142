import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SEO, VideoBackground, Logo, Link } from 'components';
import './thank-you.scss';

const FormSuccess = (props) => {
  const content = useStaticQuery(graphql`
    query ThankYouQuery {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `);

  const siteName = content?.site?.siteMetadata?.siteName;

  return (
    <div className="thanks">
      <SEO title={`Thank you | ${siteName}`} />

      <VideoBackground
        className="thanks-video"
        url="https://cleanvibes.cdn.prismic.io/cleanvibes/b40c09fb-3577-48e9-9041-500de3d62e86_Clean+Vibes+-+Event+Cleaning+Sydney.mp4"
      />

      <div className="thanks-text-container">
        <div className="thanks-card">
          <Logo className="thanks-logo" />
          <div className="thanks-text">Thanks so much for your enquiry, we'll get back to you soon.</div>
          <div className="thanks-link">
            <Link to="/" classsName="white">
              Go back to website
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSuccess;
